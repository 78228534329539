import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';

const EmailWriting = () => {
  const [step, setStep] = useState(1); // Step to control the flow: 1 - Instructions, 2 - Writing, 3 - Evaluation
  const [scenario, setScenario] = useState(null);
  const [subject, setSubject] = useState('');
  const [to, setTo] = useState('');
  const [bcc, setBcc] = useState('');
  const [body, setBody] = useState('');
  const [evaluation, setEvaluation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
   const [role, setRole] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');

//     const fetchScenario = async () => {
//         setStep(2);
//   try {
//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//           model: 'gpt-4o-mini',
//           temperature:1,
//         messages: [
//           {
//             role: 'user',
//             content: `Generate a lively random email writing scenario which targeting improving email writing skills of students to make them ready for corporate world in JSON format with the following fields:
            // {
            //   "title": "[Short descriptive title for the scenario]",
            //   "objective": "[What the user should achieve by writing the email]",
            //   "details": {
            //     "recipient_name": "[Recipient's Name]",
            //     "recipient_email": "[Recipient's Email Address, e.g., john.doe@example.com]",
            //     "purpose": "[Purpose of the email]",
            //     "additional_details": "[descriptive what required]"
            //   }
            // }
//             Ensure the scenario is realistic, engaging, and professional.`,
//           },
//         ],
//         max_tokens: 300,
//       },
//       {
//         headers: {
//           Authorization: `Bearer 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`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     const scenario = response.data.choices[0].message.content.replace(/```json|```/g, '');

//     if (!scenario) {
//       throw new Error('Received empty scenario from API.');
//     }
// console.log(JSON.parse(scenario));

//     setScenario(JSON.parse(scenario)); // Directly parse and set JSON response
//   } catch (err) {
//     console.error('Error fetching scenario:', err.message || err);
//     setError('Failed to fetch scenario. Please check your connection or API key.');
//   }
// };
const fetchScenario = async () => {
  if (!role || !additionalInfo.trim()) {
    setError('Please select your role and provide the required information.');
    return;
  }
  setError("");
  setStep(2);
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini',
        temperature: 1.2,
        messages: [
          {
            role: 'user',
            content: `Generate a unique, high-impact email writing scenario designed for a ${role} ${
              role === 'Student'
                ? `pursuing a degree in ${additionalInfo}`
                : `working in the ${additionalInfo} domain`
                  }. The scenario should be thought-provoking, realistic, and require the user to think critically about tone, structure, and purpose. Format the output as JSON with the following fields:
              {
                "title": "[Engaging and descriptive title for the scenario]",
                "objective": "[What the user should achieve by writing the email, e.g., persuading, informing, apologizing, etc.]",
                "details": {
                  "recipient_name": "[Recipient's Name]",
                  "recipient_email": "[Recipient's Email Address, e.g., jane.doe@example.com]",
                  "purpose": "[Purpose of the email, e.g., job application, partnership proposal, complaint resolution, etc.]",
                  "additional_details": "[Detailed instructions or expectations from the user while crafting the email, e.g., maintain professional tone, use clear call-to-actions, explain your intent concisely, demonstrate empathy, etc.]"
                }
              }`
          },
        ],
      },
      {
        headers: {
          Authorization: `Bearer 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`,
          'Content-Type': 'application/json',
        },
      }
    );

    const scenarioData = response.data.choices[0].message.content.replace(/```json|```/g, '');
    setScenario(JSON.parse(scenarioData));
  } catch (err) {
    console.error('Error fetching scenario:', err.message || err);
    setError('Failed to fetch scenario. Please try again later.');
  }
};

  // Function to parse and validate the scenario
  
 

 const handleSendEmail = async () => {
  // Ensure all required fields are provided
  if (!subject.trim() || !body.trim()) {
    setError('Please provide both a subject and body for the email.');
    return;
  }

  // Indicate loading state
  setLoading(true);
  setError('');

  try {
    // Make the API call to OpenAI
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'system',
            content: `You are an advanced email evaluator and generator. You will evaluate user-provided emails based on the following criteria:
1. Appropriateness: Evaluate if the email is appropriate in tone, content, and context.
2. Grammar: Check for grammatical errors, clarity, and fluency.
3. Relevance: Ensure the subject and body align with the provided scenario.
4. Human-like Tone: Suggest improvements that make the email sound more natural, as if written by a human.
5. Scoring: Provide a score between 1 (poor) and 10 (excellent).
6. Suggestions: Provide a suggested email response with a human-friendly tone and also take reference of detailed mentioned in  ${scenario.details.recipient_name} ${scenario.details.recipient_email} ${scenario.details.purpose}.

If the email is inappropriate or requires significant changes, provide a score of 0, along with a detailed suggestion for a better response in JSON format.`
          },
          {
            role: 'user',
            content: `Evaluate this email based on the following scenario:
Scenario: ${scenario.title}

Subject: ${subject}
TO: ${to}
BCC: ${bcc}

Body: ${body}

Provide the evaluation and a suggested email response in the following JSON format:
{
  "score": <integer between 1 and 10>,
  "remark": "<remarks on the user's email, including grammar, tone, and relevance>",
  "tone": "<evaluation of the tone>",
  "grammar_score": <integer between 1 and 10>,
  "suggested_email": {
    "subject": "<suggested subject>",
    "to": "<suggested recipients>",
    "bcc": "<suggested BCC>",
    "body": "<suggested body>"
  }
}`,
          },
        ],
      },
      {
        headers: {
          Authorization: `Bearer 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`, // Replace with your environment variable for production
          'Content-Type': 'application/json',
        },
      }
    );

    // Parse the evaluation result
    const evaluationContent = JSON.parse(response.data.choices[0]?.message?.content?.trim().replace(/```json|```/g, ''));
    if (!evaluationContent) {
      throw new Error('Invalid response from email evaluator.');
    }

    const evaluationData = evaluationContent; // Parse the JSON formatted result
    console.log(evaluationData);
    
    setEvaluation(evaluationData); // Update evaluation state
    setStep(3); // Move to the Evaluation step
  } catch (err) {
    console.error('Error evaluating email:', err); // Log for debugging
    setError(
      err.response?.data?.error?.message ||
      'Something went wrong while evaluating the email. Please try again later.'
    );
  } finally {
    setLoading(false);
  }
};

    const handlePracticeAgain = () => {
        setBcc("");
        setBody("");
        setError("");
        setEvaluation(null);
        setScenario(null);
        setStep(1);
        setSubject("");
        setTo("");
    };
    const handleRoleChange = (e) => {
        setError("");
    setRole(e.target.value);
    setAdditionalInfo(''); // Reset additional info on role change
  };
    const handlePrint = () => {
    window.print();
    };
    const handleCopy = () => {
        const emailContent = `
  ****Evaluation Result*****
  Evaluation score: ${evaluation.score},
  remark: ${evaluation.remark},
  tone: ${evaluation.tone},
  grammar score: ${evaluation.grammar_score},
  suggested email:
    subject: ${evaluation.suggested_email.subject},
    to: ${evaluation.suggested_email.to},
    cc: ${evaluation.suggested_email.bcccc},
    body: ${evaluation.suggested_email.body}
    `;
    navigator.clipboard.writeText(emailContent);
    alert('Email content and evaluation copied to clipboard!');
  };
const handlePaste = (e) => {
  e.preventDefault();
  alert('Pasting is disabled! Thoughtful inputs lead to perfection. Take your time!');
};
    
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-violet-900 px-4">
      {/* Instructions */}
     {step === 1 && (
  <motion.div
    className="bg-white p-10 rounded-xl shadow-xl w-full max-w-4xl"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, ease: "easeOut" }}
  >
    <h1 className="text-5xl font-extrabold text-violet-800 mb-6 text-center">
      Crafting the Perfect Email
    </h1>
    <p className="text-xl text-violet-600 mb-8 text-center">
      Master the art of effective email communication with these essential tips and an interactive practice session!
    </p>
    <ul className="list-inside list-disc text-lg text-gray-800 space-y-3 mb-8">
      <li>
        <span className="font-bold text-violet-900">Be concise:</span> Keep the subject line relevant and clear.
      </li>
      <li>
        <span className="font-bold text-violet-900">Professional tone:</span> Use polite and respectful language.
      </li>
      <li>
        <span className="font-bold text-violet-900">Structure matters:</span> Organize your email for clarity and readability.
      </li>
      <li>
        <span className="font-bold text-violet-900">Start strong:</span> Address the recipient with a proper greeting.
      </li>
    </ul>
    <p className="text-lg text-violet-600 mb-8">
      Dive into this exercise to practice writing emails tailored to specific scenarios. You'll receive actionable feedback to refine your skills.
    </p>
    <div className="mb-6">
      <label className="block text-gray-700 font-semibold mb-2">
        Select your role:
      </label>
      <div className="flex items-center gap-6 mb-4">
        <label className="flex items-center text-gray-800">
          <input
            type="radio"
            id="student"
            name="role"
            value="Student"
            onChange={handleRoleChange}
            checked={role === "Student"}
            className="mr-2 accent-violet-600"
          />
          Student
        </label>
        <label className="flex items-center text-gray-800">
          <input
            type="radio"
            id="working"
            name="role"
            value="Working Professional"
            onChange={handleRoleChange}
            checked={role === "Working Professional"}
            className="mr-2 accent-violet-600"
          />
          Working Professional
        </label>
      </div>
    </div>
    {role && (
      <div className="mb-8">
        <label className="block text-gray-700 font-semibold mb-2">
          {role === "Student"
            ? "Share your degree and specialization:"
            : "Share your industry/domain and designation:"}
        </label>
        <input
          type="text"
          value={additionalInfo}
                              onChange={(e) => {
                                  setAdditionalInfo(e.target.value); 
                                  setError("");
                              }}
          className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500 text-gray-900"
          placeholder={
            role === "Student"
              ? "e.g., B.Tech in Mechanical Engineering"
              : "e.g., IT Software Developer"
          }
        />
      </div>
    )}
    {error && <p className="text-red-500 text-center mb-6">{error}</p>}
    <div className="flex justify-center">
      <button
        onClick={fetchScenario}
        className="py-3 px-10 bg-orange-500 text-white font-bold rounded-lg shadow-lg hover:shadow-2xl hover:from-orange-600 hover:to-orange-500 transition-all duration-300 transform hover:scale-105"
      >
        Start Practice
      </button>
    </div>
  </motion.div>
)}


      {/* Email Writing */}
  {step === 2 && (
  <motion.div
    className="bg-white p-8 rounded-xl shadow-xl w-full max-w-4xl mx-auto"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.5 }}
                  onCopy={(e) => {
    e.preventDefault();
    alert('Copycat alert! Be the hero, not the mimic. Type it yourself!');
  }}
  >
    {error && <p className="text-red-500 mb-4">{error}</p>}

    {scenario ? (
      <div className="mb-6  p-6 ">
        <h3 className="text-xl sm:text-2xl font-semibold text-violet-900 mb-4">{scenario.title}</h3>
        <div className="space-y-2 sm:space-y-4 text-violet-700">
          <p className="text-sm sm:text-base"><strong>Objective:</strong><span className='text-black'> {scenario.objective}</span></p>
          <p className="text-sm sm:text-base"><strong>Recipient Name:</strong><span className='text-black'> {scenario.details.recipient_name}</span></p>
          <p className="text-sm sm:text-base"><strong>Recipient Email:</strong><span className='text-black'> {scenario.details.recipient_email}</span></p>
       
          <p className="text-sm sm:text-base"><strong>Purpose:</strong><span className='text-black'> {scenario.details.purpose}</span></p>
          <p className="text-sm sm:text-base"><strong>Additional Details:</strong><span className='text-black'> {scenario.details.additional_details}</span></p>
        </div>

        {/* Render inputs and button after scenario is loaded */}
        <div className=" mt-6">
          
          <input
            type="text"
            className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500 text-black"
            placeholder="TO"
            value={to}
                                  onChange={(e) => setTo(e.target.value)}
                                  onPaste={handlePaste}
          />
          <input
            type="text"
            className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500 text-black"
            placeholder="CC/BCC"
            value={bcc}
                                  onChange={(e) => setBcc(e.target.value)}
                                  onPaste={handlePaste}
                              />
                              <input
            type="text"
            className="w-full p-3 mb-8 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500 text-black"
            placeholder="Subject"
            value={subject}
                                  onChange={(e) => setSubject(e.target.value)}
                                  onPaste={handlePaste}
          />
          <textarea
            className="w-full p-4 mb-4 mt-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-black"
            rows="10"
            placeholder="Write your email here..."
            value={body}
                                  onChange={(e) => setBody(e.target.value)}
                                  onPaste={handlePaste}
          />
        </div>

        <button
          onClick={handleSendEmail}
          className="w-full py-3 mt-6 bg-orange-500 text-white font-bold rounded-lg shadow-lg hover:shadow-2xl hover:from-orange-600 hover:to-orange-500 transition-all duration-300 text-lg font-semibold"
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send'}
        </button>
      </div>
    ) : (
      // Show loading spinner and message until scenario is loaded
      <div className="flex justify-center items-center mb-6">
        <svg className="animate-spin h-8 w-8 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4V1m0 22v-3m-8-7H1m22 0h-3M5.59 6.59l-1.42-1.42m14.84 0l-1.42 1.42M5.59 17.41l-1.42 1.42m14.84 0l-1.42-1.42" />
        </svg>
        <p className="ml-3 text-gray-500 text-sm sm:text-base">Loading scenario...</p>
      </div>
    )}
  </motion.div>
)}


    {/* Evaluation */}
{step === 3 && evaluation && (
  <motion.div
    className="bg-white p-6 rounded-lg  max-w-4xl"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 1, delay: 0.5 }}
  >
    <h2 className="text-4xl font-bold mb-6 text-violet-900 text-center">
      Evaluation Result
    </h2>

    <div className="bg-white p-6 rounded-xl border shadow-lg relative">
      <h3 className="text-2xl font-semibold text-violet-800 mb-4 mt-8">Evaluation Score:</h3>
      <p
        className={`text-4xl font-bold ${
          evaluation.score < 5
            ? "text-red-600"
            : evaluation.score <= 7
            ? "text-yellow-500"
            : "text-green-600"
        } flex items-center gap-3`}
      >
        {evaluation.score}/10
        
      </p>

      <h3 className="text-2xl font-semibold text-violet-800 mt-6 mb-4">Remarks:</h3>
      <p
        className={`text-lg font-medium ${
          evaluation.score < 5
            ? "text-red-600"
            : evaluation.score <= 7
            ? "text-yellow-500"
            : "text-green-600"
        }`}
      >
        {evaluation.remark}
      </p>
<h3 className="text-2xl font-semibold text-violet-800 mt-6 mb-4">Tone:</h3>
      <p
        className={`text-lg font-medium ${
          evaluation.score < 5
            ? "text-red-600"
            : evaluation.score <= 7
            ? "text-yellow-500"
            : "text-green-600"
        }`}
      >
        {evaluation.tone}
                      </p>
                      <h3 className="text-2xl font-semibold text-violet-800 mb-4 mt-8">Grammar Score:</h3>
      <p
        className={`text-4xl font-bold ${
          evaluation.grammar_score < 5
            ? "text-red-600"
            : evaluation.grammar_score <= 7
            ? "text-yellow-500"
            : "text-green-600"
        } flex items-center gap-3`}
      >
        {evaluation.grammar_score}/10
        
      </p>
      <h3 className="text-2xl font-semibold text-violet-800 mt-6 mb-4">Suggested Email:</h3>
      <div className="border rounded-xl p-6 bg-gray-50">
        {/* Render the email details */}
        <p className="mb-2">
          <span className="font-semibold">Subject:</span> {evaluation.suggested_email.subject}
        </p>
        <p className="mb-2">
          <span className="font-semibold">To:</span> {evaluation.suggested_email.to}
        </p>
        {evaluation.suggested_email.bcc && (
          <p className="mb-2">
            <span className="font-semibold">BCC:</span> {evaluation.suggested_email.bcc}
          </p>
        )}
        <div className="mt-4 border-t pt-4">
          {/* Render the body as a formatted email */}
          <div className="text-gray-800 whitespace-pre-line">
            {evaluation.suggested_email.body}
          </div>
        </div>
      </div>
    <div
  className={`absolute top-0 right-0 px-3 py-1 rounded-bl-lg text-white animate-pulse ${
    evaluation.score < 5
      ? "bg-gradient-to-br from-red-500 to-red-700"
      : evaluation.score <= 7
      ? "bg-gradient-to-br from-yellow-400 to-yellow-600"
      : "bg-gradient-to-br from-green-400 to-green-600"
  }`}
>
  {evaluation.score < 5 && "Needs Improvement!"}
  {evaluation.score >= 5 && evaluation.score <= 7 && "Good Effort!"}
  {evaluation.score > 7 && "Excellent!"}
</div>

                  </div>
                    <div className="mt-8 flex justify-center space-x-4">
        <button
          onClick={handlePrint}
          className="px-4 py-2 bg-violet-900 text-white rounded-md hover:bg-violet-700 transition duration-300"
        >
          Print Results
        </button>
        <button
          onClick={handleCopy}
          className="px-4 py-2 bg-violet-500 text-white rounded-md hover:bg-violet-400 transition duration-300"
        >
          Copy Results
        </button>
      </div>

    <button
      onClick={() => handlePracticeAgain()}
      className="w-full py-3 mt-8 bg-orange-500 text-white font-bold rounded-lg shadow-lg hover:shadow-2xl hover:from-orange-600 hover:to-orange-500 text-lg font-semibold"
    >
      Practice Again
    </button>
  </motion.div>
)}

    </div>
  );
};

export default EmailWriting;
