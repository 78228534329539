import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import LoginPage from "../components/LoginPage";
import FileUpload from "../components/FileUpload";
import InterviewPage from "../components/InterviewPage";
import Results from "../components/Results";
import Navbar from "./Navbar";
import ComingSoon from "../components/CommingSoon";
import EmailWriting from "../components/EmailWriting";
import Aptitude from "../components/Apptitude";
import CodingPractice from "../components/CodingPractice";
import ResumeBuilder from "../components/ResumeBuilder";
const Layout = () => (
  <div className="flex flex-col md:flex-row min-h-screen bg-gray-200">
    <Navbar />
    <div className="flex-1 p-4 md:ml-64">
      <Outlet />
    </div>
  </div>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/upload",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <FileUpload />,
      },
    ],
  },
  {
    path: "/mock_interview",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <InterviewPage />,
      },
    ],
  },
  {
    path: "/results",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Results />,
      },
    ],
  },
  {
    path: "/coming-soon",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <ComingSoon />,
      },
    ],
    },
  {
    path: "/Email_Writing",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <EmailWriting />,
      },
    ],
    },
  {
    path: "/Coding_Practice",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <CodingPractice />,
      },
    ],
    },
  {
    path: "/Aptitude",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Aptitude />,
      },
    ],
    },
  {
    path: "/ResumeBuilder",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <ResumeBuilder />,
      },
    ],
  },
]);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;