import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import mammoth from 'mammoth';
import axios from 'axios';
pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const FileUpload = ({ onFilesUploaded, onStartInterview }) => {
  const [resumeFile, setResumeFile] = useState(null);
  const [jobDescriptionFile, setJobDescriptionFile] = useState(null);
  const [resumeText, setResumeText] = useState('');
  const [jobDescriptionText, setJobDescriptionText] = useState('');
  const [useResumeTextInput, setUseResumeTextInput] = useState(false);
  const [useJobDescriptionTextInput, setUseJobDescriptionTextInput] = useState(false);
  const [resumeSubmissionStatus, setResumeSubmissionStatus] = useState(false);
  const [jobDescriptionSubmissionStatus, setJobDescriptionSubmissionStatus] = useState(false);
    const [loading, setLoading] = useState(false);
  
  const summarizeText = async (text) => {
    try {
    setLoading(true);
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: `Summarize this text: ${text}` }],
        max_tokens: 200,
      },
      {
        headers: {
          Authorization: `Bearer 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`,
          'Content-Type': 'application/json',
        },
      }
    );
    setLoading(false);
    return response.data.choices[0].message.content.trim();
    } catch (err) {
      setLoading(false);
    return text; // Return original if error occurs
  }
};

  // Handle file drop for resume and job description
  const handleFileDrop = async (acceptedFiles, setFile, type) => {
    const file = acceptedFiles[0];
    setFile(file);

    // Extract text from file based on file type
    if (file.type === 'application/pdf') {
      const text = await extractTextFromPDF(file);
      const summarizedText = await summarizeText(text);
      onFilesUploaded({ type, content: summarizedText });
    } else if (file.type === 'text/plain') {
      const reader = new FileReader();
      reader.onload = async () => {
        const content = reader.result;
      const summarizedText = await summarizeText(content);
      onFilesUploaded({ type, content: summarizedText });
      };
      reader.readAsText(file);
    } else if (
      file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      file.type === 'application/msword'
    ) {
      const text = await extractTextFromWord(file);
      const summarizedText = await summarizeText(text);
      onFilesUploaded({ type, content: summarizedText });
    } else {
      alert('Unsupported file format. Please upload a TXT, PDF, or Word (DOC/DOCX) file.');
      setFile(null);
    }
  };

  // Extract text from PDF file
  const extractTextFromPDF = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
    let text = '';
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      const pageText = content.items.map((item) => item.str).join(' ');
      text += `${pageText}\n`;
    }
    return text.trim();
  };

  // Extract text from Word file
  const extractTextFromWord = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const { value: text } = await mammoth.extractRawText({ arrayBuffer });
    return text.trim();
  };

  // Manual text input handler for resume
  const handleResumeManualInput = async () => {
    if (!resumeText.trim()) {
      alert('Please enter the resume text.');
      return;
    }
     const summarizedText = await summarizeText(resumeText.trim());
    onFilesUploaded({ type: 'resume', content: summarizedText });
    setResumeSubmissionStatus(true);
  };

  // Manual text input handler for job description
  const handleJobDescriptionManualInput = async () => {
    if (!jobDescriptionText.trim()) {
      alert('Please enter the job description text.');
      return;
    }
    const summarizedText = await summarizeText(jobDescriptionText.trim());
    onFilesUploaded({ type: 'jobDescription', content: summarizedText });
    setJobDescriptionSubmissionStatus(true);
  };

  // Disable the Start Interview button unless both files are uploaded
  const isStartInterviewDisabled = (resumeFile || (resumeSubmissionStatus && resumeText.trim())) && (jobDescriptionFile || (jobDescriptionSubmissionStatus && jobDescriptionText.trim()))&&!loading;

   return (
    <div className="p-6   text-white">
      <h1 className="text-2xl font-bold text-center mb-6 text-violet-900">
        Upload Your Files
      </h1>

      <div className="space-y-6">
        {/* Resume Upload */}
        <div>
          <h3 className="font-bold text-lg mb-2 text-violet-900">Resume</h3>
          <div className="flex items-center space-x-4 mb-4">
            <button
              onClick={() => {
                setUseResumeTextInput(false);
                setResumeSubmissionStatus(false);
              }}
              className={`py-2 px-4 rounded-lg text-sm font-medium ${
                !useResumeTextInput ? 'bg-orange-500 text-white' : 'bg-gray-400 text-white'
              }`}
            >
              Upload File
            </button>
            <button
              onClick={() => {
                setUseResumeTextInput(true);
                setResumeSubmissionStatus(false);
              }}
              className={`py-2 px-4 rounded-lg text-sm font-medium ${
                useResumeTextInput ? 'bg-orange-500 text-white' : 'bg-gray-400 text-white'
              }`}
            >
              Enter Text
            </button>
          </div>
         {!useResumeTextInput ? (
            <Dropzone
              onDrop={(files) => handleFileDrop(files, setResumeFile, 'resume')}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className="p-6 border-dashed border-2 border-gray-500 rounded-lg text-center cursor-pointer bg-white hover:bg-gray-200 transition"
                >
                  <input {...getInputProps()} />
                  <p className="text-gray-500">
                    Drag & drop your resume here, or <span className="text-violet-900 font-semibold">click</span> to select it
                    (TXT/PDF/DOC/DOCX)
                  </p>
                </div>
              )}
            </Dropzone>
          ) : (
            <textarea
              className="w-full p-3 border rounded-lg text-sm bg-white text-black"
              rows="5"
              placeholder="Enter the resume text here..."
              value={resumeText}
              onChange={(e) => {
                setResumeText(e.target.value);
                setResumeSubmissionStatus(false);
              }}
            />
          )}
          {resumeFile && !useResumeTextInput && (
            <p className="text-sm mt-2 text-green-500 font-medium">{resumeFile.name}</p>
          )}

          {useResumeTextInput && (
            <button
              onClick={handleResumeManualInput}
              className="w-full py-2 mt-3 bg-orange-500 text-white rounded-lg hover:bg-orange-700 transition duration-300"
            >
              Submit Resume
            </button>
          )}

          {resumeSubmissionStatus && (
            <div className="p-3 bg-white text-green-500 border border-green-500 rounded-lg mt-4 text-center">
              <p>Resume submitted successfully!</p>
            </div>
          )}
        </div>

        {/* Job Description Section */}
        <div>
          <h3 className="font-bold text-lg mb-2 text-violet-900">Job Description</h3>
          <div className="flex items-center space-x-4 mb-4">
            <button
              onClick={() => {
                setUseJobDescriptionTextInput(false);
                setJobDescriptionSubmissionStatus(false);
              }}
              className={`py-2 px-4 rounded-lg text-sm font-medium ${
                !useJobDescriptionTextInput ? 'bg-orange-500 text-white' : 'bg-gray-400 text-white'
              }`}
            >
              Upload File
            </button>
            <button
              onClick={() => {
                setUseJobDescriptionTextInput(true);
                setJobDescriptionSubmissionStatus(false);
              }}
              className={`py-2 px-4 rounded-lg text-sm font-medium ${
                useJobDescriptionTextInput ? 'bg-orange-500 text-white' : 'bg-gray-400 text-white'
              }`}
            >
              Enter Text
            </button>
          </div>

          {!useJobDescriptionTextInput ? (
            <Dropzone
              onDrop={(files) =>
                handleFileDrop(files, setJobDescriptionFile, 'jobDescription')
              }
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className="p-6 border-dashed border-2 border-gray-500 rounded-lg text-center cursor-pointer bg-white hover:bg-gray-200 transition"
                >
                  <input {...getInputProps()} />
                  <p className="text-gray-500">
                    Drag & drop the job description here, or <span className="text-violet-900 font-semibold">click</span> to select it
                    (TXT/PDF/DOC/DOCX)
                  </p>
                </div>
              )}
            </Dropzone>
          ) : (
            <textarea
              className="w-full p-3 border rounded-lg text-sm bg-white text-black"
              rows="5"
              placeholder="Enter the job description text here..."
              value={jobDescriptionText}
              onChange={(e) => {
                setJobDescriptionText(e.target.value);
                setJobDescriptionSubmissionStatus(false);
              }}
            />
          )}

          {jobDescriptionFile && !useJobDescriptionTextInput && (
            <p className="text-sm mt-2 text-green-500 font-medium">{jobDescriptionFile.name}</p>
          )}

          {useJobDescriptionTextInput && (
            <button
              onClick={handleJobDescriptionManualInput}
              className="w-full py-2 mt-3 bg-orange-500 text-white rounded-lg hover:bg-indigo-700 transition duration-300"
            >
              Submit Job Description
            </button>
          )}

          {jobDescriptionSubmissionStatus && (
            <div className="p-3 bg-white text-green-500 border border-green-500 rounded-lg mt-4 text-center">
              <p>Job description submitted successfully!</p>
            </div>
          )}
        </div>
      </div>
      {/* Start Interview Button */}
      <button
        onClick={onStartInterview}
        disabled={!isStartInterviewDisabled}
        className={` ${
          isStartInterviewDisabled
            ? 'w-full py-3 mt-6 bg-violet-900 text-white rounded-lg hover:bg-violet-600 transition duration-300 text-lg font-semibold'
            : 'w-full py-3 mt-6 bg-gray-600 cursor-not-allowed text-white rounded-lg transition duration-300 text-lg font-semibold'
        }`}
      >
        {loading ? (
          <span className="flex justify-center items-center space-x-2">
            <div className="animate-spin rounded-full border-t-2 border-white w-5 h-5" />
            <span>Summarizing...</span>
          </span>
        ) : (
          'Start Interview'
        )}
      </button>
    </div>
  );
};

export default FileUpload;