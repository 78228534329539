import React, { useState , useEffect, useRef } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { jsPDF } from "jspdf";
import { Document, Packer, Paragraph,TextRun,Alignment,HeadingLevel,AlignmentType } from "docx";
import { saveAs } from "file-saver";
import mammoth from "mammoth"; 

const ResumeBuilder = () => {
  const [step, setStep] = useState(1);
  const [role, setRole] = useState('');
  const [stream, setStream] = useState('');
  const [domain, setDomain] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [resumeData, setResumeData] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [docxFile, setDocxFile] = useState(null);
  
      const chatContainerRef = useRef(null); // Create a ref for the chat container
 useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory, currentQuestionIndex]);
  const CustomDropdown = ({ value, onChange, options, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

     
  // Automatically scroll to the bottom when the chat or question changes
 
  const handleSelect = (option) => {
    setSelectedValue(option);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="relative w-full">
      <label className="block text-xl font-semibold text-violet-700 mb-3">{label}</label>
      <div
        className="w-full p-3 border-2 border-gray-300 rounded-lg bg-white text-black cursor-pointer focus:outline-none focus:ring-4 focus:ring-violet-500 transition-all duration-300 hover:shadow-lg flex justify-between items-center"
        onClick={toggleDropdown}
      >
        <span className="text-gray-700">{selectedValue || "Select your role"}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 text-gray-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>

      {isOpen && (
        <motion.div
          className="absolute w-full mt-2 rounded-lg bg-white shadow-lg border border-gray-300 max-h-60 overflow-y-auto z-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <ul className="divide-y divide-gray-200">
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleSelect(option)}
                className="cursor-pointer p-3 hover:bg-violet-100 transition-all duration-200"
              >
                {option}
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </div>
  );
};
    
  const handleNextStep = async () => {
  setStep(2);
    setLoading(true);
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o-mini',
          messages: [
            {
              role: 'user',
              content: `Generate a list of questions to gather information for building the best resume for a ${role} in ${role === 'fresher' ? stream : domain} start with presonal details like name, email , ohone number and linkedin profile. Provide the response in JSON format as below:
                {
                  "questions": [
                    "<question1>",
                    "<question2>",
                    ...
                  ]
                }`,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer 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`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Parse the questions directly from the response, if it is valid JSON
      const questionsContent = response.data.choices[0].message.content;
      if (questionsContent) {
          const questionsParsed = JSON.parse(questionsContent.replace(/```json|```/g, ''));
          console.log(questionsParsed);
          
        setQuestions(questionsParsed.questions); // Update the state with questions
       
      } else {
        setError('Failed to parse questions. Please try again.');
      }
    } catch (err) {
      setError('Failed to fetch questions. Please try again.');
    } finally {
      setLoading(false);
    }
 
};


  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleChatInput = (input) => {
    const currentQuestion = questions[currentQuestionIndex];
    setAnswers({ ...answers, [currentQuestion]: input });
    setChatHistory([
      ...chatHistory,
      { role: 'bot', content: currentQuestion },
      { role: 'user', content: input },
    ]);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const fetchFresherResume = async () => {
  setLoading(true);
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'system',
            content: `
              Generate a JSON object for a fresher's resume in the following format:
              {
                "name": "string",
                "contact_information": {
                  "phone": "string",
                  "email": "string",
                  "location": "string",
                  "linkedin": "string (optional)"
                },
                "objective": "string",
                "education": [
                  {
                    "degree": "string",
                    "institution": "string",
                    "year": "string",
                    "cgpa_or_percentage": "string"
                  }
                ],
                 "skills": {
                  "technical_skills": ["string"],
                  "tools_and_technologies": ["string"]
                },
                "projects": [
                  {
                    "title": "string",
                    "description": "string",
                    "technologies_used": ["string"]
                  }
                ],
                "internships": [
                  {
                    "role": "string",
                    "company": "string",
                    "duration": "string",
                    "responsibilities": ["string"]
                  }
                ],
                "achievements": ["string"],
                "soft_skills": ["string"]
              }
              Ensure that the response strictly adheres to this JSON format.
            `
          },
          ...Object.entries(answers).map(([question, answer]) => ({
            role: 'user',
            content: `${question}: ${answer}`,
          })),
        ],
      },
      {
        headers: {
          Authorization: `Bearer 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`, // Replace with your actual API key
          'Content-Type': 'application/json',
        },
      }
    );

    const generatedContent = JSON.parse(response.data.choices[0].message.content.replace(/```json|```/g, ''));
    downloadDOCX(generatedContent);
    // Use or manipulate the JSON response as needed
    setResumeData(generatedContent);

  } catch (error) {
    console.error('Error generating fresher resume:', error);
  } finally {
    setLoading(false);
  }
  };
  
  const fetchExperiencedResume = async () => {
  setLoading(true);
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'system',
            content: `
              Generate a JSON object for an experienced professional's  resume based on the user's answers to the questions you have free hand to do modification to provide best resume. also if any section answer or user not provided data then do not provide that section in response. in the following format:
              {
                "name": "string",
                "contact_information": {
                  "phone": "string",
                  "email": "string",
                  "location": "string",
                  "linkedin": "string (optional)",
                  "github": "string (optional)"
                },
                "professional_summary": "string",
                "work_experience": [
                  {
                    "role": "string",
                    "company": "string",
                    "duration": "string",
                    "key_responsibilities": ["string"]
                  }
                ],
                "skills": {
                  "technical_skills": ["string"],
                  "tools_and_technologies": ["string"]
                },
                "projects": [
                  {
                    "title": "string",
                    "description": "string",
                    "technologies_used": ["string"]
                  }
                ],
                "education": [
                  {
                    "degree": "string",
                    "institution": "string",
                    "year": "string"
                  }
                ],
                "certifications": [
                  {
                    "name": "string",
                    "issuer": "string",
                    "year": "string"
                  }
                ],
                "achievements": ["string"]
              }
              Ensure that the response strictly adheres to this JSON format.
            `
          },
          ...Object.entries(answers).map(([question, answer]) => ({
            role: 'user',
            content: `${question}: ${answer}`,
          })),
        ],
      },
      {
        headers: {
          Authorization: `Bearer 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`, // Replace with your actual API key
          'Content-Type': 'application/json',
        },
      }
    );

    const generatedContent = JSON.parse(response.data.choices[0].message.content.replace(/```json|```/g, ''));

    // Use or manipulate the JSON response as needed
    setResumeData(generatedContent);
    downloadDOCX(generatedContent);
  } catch (error) {
    console.error('Error generating experienced resume:', error);
  } finally {
    setLoading(false);
  }
};

  const downloadPDF = (docxFile) => {
    if (!docxFile) {
      alert("Please select a DOCX file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const arrayBuffer = e.target.result;

      // Use Mammoth.js to convert DOCX to HTML
      const { value: htmlContent } = await mammoth.convertToHtml({ arrayBuffer });

      // Use jsPDF to generate a PDF from the HTML content
      const pdf = new jsPDF();

      // Add HTML content to the PDF
      pdf.html(htmlContent, {
        callback: (doc) => {
          doc.save("converted_resume.pdf");  // Trigger PDF download
        },
        margin: [10, 10, 10, 10], // Define margins
      });
    };

    reader.readAsArrayBuffer(docxFile); 
  };

 const downloadDOCX = async (content) => {
  const {
    name,
    contact_information,
    objective,
    education,
    skills,
    projects,
    work_experience,
    certifications,
    achievements,
  } = content;

  const doc = new Document({
    styles: {
      paragraphStyles: [
        {
          id: "Heading1",
          name: "Heading 1",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Arial",
            size: 48,
            bold: true,
            color: "#7500D1", // Dark Blue for Name
          },
          paragraph: {
            alignment: AlignmentType.LEFT,
            spacing: { after: 300 },
          },
        },
        {
          id: "Heading2",
          name: "Heading 2",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Arial",
            size: 24,
            bold: true,
            color: "#7500D1",
            underline:true// Dark Slate Gray for Section Headers
          },
          paragraph: {
            spacing: { before: 200, after: 100 },
            alignment: AlignmentType.LEFT,
          },
        },
        {
          id: "BodyText",
          name: "Body Text",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Arial",
            size: 20,
            color: "000000", // Standard Dark Gray
          },
          paragraph: {
            spacing: { after: 150 },
            alignment: AlignmentType.LEFT,
          },
        },
         {
          id: "ContactText",
          name: "Contact Text",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          
          run: {
            font: "Arial",
            bold: true,
            size: 20,
            color: "#ff9636", // Standard Dark Gray
          },
          paragraph: {
            //spacing: { after: 1 },
            alignment: AlignmentType.LEFT,
          },
        },
        {
          id: "BulletText",
          name: "Bullet Text",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            //font: "Roboto",
            size: 24,
            color: "000000",
          },
          paragraph: {
            bullet: { type: "Bullet" },
            spacing: { after: 100 },
            alignment: AlignmentType.LEFT,
          },
        },
      ],
    },
    sections: [
      {
        children: [
          // Header Section (Name & Contact Info)
          new Paragraph({
            text: name,
            style: "Heading1",
          }),
          new Paragraph({
            text: `${contact_information.email} | ${contact_information.phone} | ${contact_information.location} | ${contact_information.linkedin}`,
            style: "ContactText",
          }),
         
          contact_information.github &&
            new Paragraph({
              text: `GitHub: ${contact_information.github}`,
              style: "BodyText",
            }),

          // Divider Line after header
        

          // Objective Section
          objective &&
            new Paragraph({
              text: "Objective",
              style: "Heading2",
              underline: true,
            }),
          objective &&
            new Paragraph({
              text: objective,
              style: "BodyText",
            }),

          // Divider Line after Objective
          

          // Education Section
          education &&
            new Paragraph({
              text: "Education",
              style: "Heading2",
            }),
          ...education.map((edu) =>
            new Paragraph({
              text: `${edu.degree}, ${edu.institution} (${edu.year}) - CGPA: ${edu.cgpa_or_percentage}`,
              style: "BodyText",
            })
          ),

          // Divider Line after Education
         

          // Skills Section
          skills &&
            new Paragraph({
              text: "Skills",
              style: "Heading2",
            }),
          new Paragraph({
            text: `Technical Skills: ${skills?.technical_skills?.join(", ")}`,
            style: "BodyText",
          }),
          new Paragraph({
            text: `Tools & Technologies: ${skills?.tools_and_technologies?.join(", ")}`,
            style: "BodyText",
          }),

          // Divider Line after Skills
         

          // Work Experience Section
          work_experience &&
            new Paragraph({
              text: "Work Experience",
              style: "Heading2",
            }),
          ...(work_experience || []).map((exp) =>
            new Paragraph({
              text: `${exp.role}, ${exp.company} (${exp.duration})`,
              style: "BodyText",
              children: exp.key_responsibilities.map(
                (resp) =>
                  new Paragraph({
                    text: resp,
                    style: "BulletText",
                  })
              ),
            })
          ),

          // Divider Line after Work Experience
         

          // Projects Section
          projects &&
            new Paragraph({
              text: "Projects",
              style: "Heading2",
            }),
          ...(projects || []).map((proj) =>
            new Paragraph({
              text: `${proj.title}: ${proj.description}`,
              style: "BodyText",
              children: [
                new Paragraph({
                  text: `Technologies: ${proj.technologies_used.join(", ")}`,
                  style: "BodyText",
                }),
              ],
            })
          ),

          // Divider Line after Projects
          
          // Certifications Section
          certifications &&
            new Paragraph({
              text: "Certifications",
              style: "Heading2",
            }),
          ...(certifications || []).map((cert) =>
            new Paragraph({
              text: `${cert.name} (${cert.issuer}, ${cert.year})`,
              style: "BodyText",
            })
          ),

          // Divider Line after Certifications
         
          // Achievements Section
          (achievements || []) &&
            new Paragraph({
              text: "Achievements",
              style: "Heading2",
            }),
          ...(achievements || []).map((ach) =>
            new Paragraph({
              text: ach,
              style: "BulletText",
            })
          ),
        ].filter(Boolean),
      },
    ],
  });

  // Export Document
  const blob = await Packer.toBlob(doc);
   saveAs(blob, `${name}_Resume.docx`);
   setDocxFile(blob);
};



  const downloadTXT = (content) => {
    const blob = new Blob([content], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Resume.txt";
    link.click();
    URL.revokeObjectURL(link.href);
  };


  return (
    <div className="min-h-screen flex flex-col items-center justify-center text-violet-900 relative w-full">
{step === 1 && (
  <div className="p-8 rounded-xl shadow-xl bg-white w-full max-w-4xl">
      <motion.h1
        className="text-4xl font-extrabold text-violet-900 mb-6"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        Crafting the Perfect Resume
      </motion.h1>

      <motion.p
        className="text-violet-500 mb-4"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        Your resume is the gateway to your career. It showcases your skills, experiences, and education. A well-crafted resume can make you stand out and increase your chances of landing an interview.
      </motion.p>

      <motion.p
        className="text-violet-500 mb-6"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        Make it clear, concise, and tailored to your dream job. Highlight the most relevant aspects of your profile and ensure it's error-free for the best first impression.
      </motion.p>

      <motion.div
        className="mb-6"
               initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.7 }}
      >
        <CustomDropdown
          value={role}
          onChange={setRole}
          options={["Fresher", "Experienced"]}
          label="Are you a fresher or experienced?"
        />
      </motion.div>

      {/* Fresher Input */}
      {role === "Fresher" && (
        <motion.div
          className="mb-6"
         
        >
          <label className="block text-xl font-semibold text-violet-700 mb-3">
            Which stream do you want to build your resume for?
          </label>
          <input
            type="text"
            className="w-full p-3 border-2 border-gray-300 rounded-lg bg-white text-black placeholder-gray-400 focus:outline-none focus:ring-4 focus:ring-violet-500 transition-all duration-300 hover:shadow-md"
            placeholder="e.g., Computer Science, Mechanical Engineering"
          />
        </motion.div>
      )}

      {/* Experienced Input */}
      {role === "Experienced" && (
        <motion.div
          className="mb-6"
         
        >
          <label className="block text-xl font-semibold text-violet-700 mb-3">
            Which domain do you want to build your resume for?
          </label>
          <input
            type="text"
            className="w-full p-3 border-2 border-gray-300 rounded-lg bg-white text-black placeholder-gray-400 focus:outline-none focus:ring-4 focus:ring-violet-500 transition-all duration-300 hover:shadow-md"
            placeholder="e.g., Software Development, Marketing"
          />
        </motion.div>
      )}

      {/* Next Button */}
      {role  && (
                      <button
                           onClick={handleNextStep}
          className="w-full px-6 py-3 bg-gradient-to-r from-orange-500 to-orange-600 text-white font-bold rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 "
        >
          Next
        </button>
      )}
    </div>
)}


{step === 2 && (
  <div className="p-6 rounded-lg shadow-xl bg-white w-full max-w-4xl flex flex-col gap-4 mx-auto">
{!loading ? (
        <div>
          <h1 className="text-4xl font-semibold text-violet-900 mb-6 text-center sm:text-3xl md:text-4xl">
            Let's Dive Deeper! Crafting Your Perfect Career Story
          </h1>

          <div
            ref={chatContainerRef} // Assign the ref to the chat container
            className="chat-container flex flex-col gap-4 overflow-y-auto h-96 p-4 bg-white rounded-lg border border-gray-300 shadow-lg"
          >
            {chatHistory.map((chat, index) => (
              <div
                key={index}
                className={`flex ${chat.role === 'user' ? 'justify-end' : 'justify-start'} transition-all duration-300`}
              >
                <p
                  className={`max-w-full sm:max-w-xs px-6 py-3 rounded-lg border ${
                    chat.role === 'user'
                      ? 'bg-violet-600 text-white rounded-br-none shadow-lg transform transition-all duration-200 hover:scale-105 break-words'
                      : 'bg-gray-100 text-gray-800 rounded-bl-none shadow-lg transform transition-all duration-200 hover:scale-105 break-words'
                  }`}
                >
                  {chat.content}
                </p>
              </div>
            ))}

            {/* Render Current Question as a Chat Message */}
            {currentQuestionIndex < questions.length && (
              <div className="flex justify-start">
                <p className="max-w-full sm:max-w-xs px-6 py-3 rounded-lg border bg-gray-100 text-gray-800 rounded-bl-none shadow-lg break-words">
                  {questions[currentQuestionIndex]}
                </p>
              </div>
            )}
          </div>

          {/* User Input Section */}
          {currentQuestionIndex < questions.length ? (
            <div className="flex flex-col gap-6 mt-6">
              <div className="flex gap-4 items-center flex-wrap">
                <input
                  type="text"
                  placeholder="Type your answer..."
                  className="flex-1 px-5 py-3 rounded-full bg-white border-2 border-gray-300 text-gray-800 focus:outline-none focus:ring-2 focus:ring-violet-500 shadow-sm transition"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleChatInput(e.target.value);
                      e.target.value = ''; // Clear the input after submission
                    }
                  }}
                />
                <button
                  className="self-center px-8 py-3 rounded-full bg-orange-500 text-white hover:bg-orange-400 transition duration-300 f shadow-lg mt-4 sm:mt-0 w-full sm:w-auto"
                  onClick={() => {
                    const inputElement = document.querySelector('input[type="text"]');
                    if (inputElement && inputElement.value) {
                      handleChatInput(inputElement.value);
                      inputElement.value = '';
                    }
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          ) : (
            <button
              onClick={() => setStep(3)}
            className="self-center px-8 py-3 mt-12 rounded-full bg-orange-500 text-white hover:bg-orange-400 transition duration-300 focus:outline-none focus:ring-2 focus:ring-orange-500 shadow-lg"
            >
              Next
            </button>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center mb-6">
          <svg className="animate-spin h-10 w-10 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4V1m0 22v-3m-8-7H1m22 0h-3M5.59 6.59l-1.42-1.42m14.84 0l-1.42 1.42M5.59 17.41l-1.42 1.42m14.84 0l-1.42-1.42" />
          </svg>
          <p className="ml-4 text-gray-500 text-sm sm:text-base">Loading...</p>
        </div>
      )}
  </div>
)}


        
      
      {step === 3 && (
  <div className="p-6 rounded-lg shadow-lg bg-white w-full max-w-4xl mx-auto text-violet-900">
    <h1 className="text-4xl font-bold mb-6 text-center">Create Your Dream Resume</h1>
    
    <button
      onClick={role === 'Fresher' ? fetchFresherResume : fetchExperiencedResume}
      disabled={loading}
      className={`w-full px-6 py-3 rounded-lg mb-6 font-semibold transition duration-300 text-white ${
        loading ? 'bg-orange-300 cursor-not-allowed' : 'bg-orange-500 hover:bg-orange-400'
      }`}
    >
      {loading ? 'Generating...' : 'Generate Resume'}
    </button>
    
    {/* {resumeData && (
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4">Your Resume:</h2>
        <pre className="bg-white text-sm text-black rounded-lg overflow-auto mb-6 p-6 font-sans leading-relaxed shadow-lg">
  {resumeData}
</pre>

      <div className="mt-8 flex justify-center space-x-4">
        <button
          onClick={()=>downloadDOCX(resumeData)}
          className="px-4 py-2 bg-violet-900 text-white rounded-md hover:bg-violet-500 transition duration-300"
        >
          Download DOCX
        </button>
        <button
          onClick={()=>downloadPDF(docxFile)}
          className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-green-500 transition duration-300"
        >
          Download TXT
        </button>
      </div>
      </div>
    )} */}
  </div>
)}

    </div>
  );
};

export default ResumeBuilder;