import React from 'react';
import { motion } from 'framer-motion';

const Results = ({ evaluation }) => {
  const { overallRemarks, questionWiseFeedback, standardAspectsFeedback } = evaluation;

  const overallScore = questionWiseFeedback.reduce((sum, x) => sum + Number(x.score), 0) / 10;

  const emoji = overallScore >= 7 ? '🎉' : '😞';
  const message = overallScore >= 7 ? 'You passed the interview!' : 'You did not pass. Try again!';

  const getColorForScore = (score) => {
    if (score < 5) return 'text-red-600';
    if (score >= 5 && score < 7) return 'text-yellow-600';
    return 'text-green-700';
  };

  const getBgColorForScore = (score) => {
    if (score < 5) return ' bg-red-100 border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 text-white ';
    if (score >= 5 && score < 7) return 'border-yellow-500 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500 text-white bg-yellow-100';
    return 'border-green-500 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-white bg-green-100';
  };
  const getBgColorForProgrssBar = (score) => {
    if (score < 5) return 'bg-red-500';
    if (score >= 5 && score < 7) return 'bg-yellow-500';
    return 'bg-green-500'
  }

  const handlePrint = () => {
    window.print();
  };

  const handleCopy = () => {
    const resultText = `
      Overall Score: ${overallScore} / 10
      Overall Remarks: ${overallRemarks}
      ${questionWiseFeedback.map(feedback => `
        Question: ${feedback.question}
        Score: ${feedback.score} / 10
        Remarks: ${feedback.remarks}
        Best Possible Answer: ${feedback.bestAnswer}
      `).join('\n')}
      Communication Skills: ${standardAspectsFeedback.communicationSkills}
      Problem-Solving Ability: ${standardAspectsFeedback.problemSolvingAbility}
      Technical Knowledge: ${standardAspectsFeedback.technicalKnowledge}
      Cultural Fit: ${standardAspectsFeedback.culturalFit}
      Leadership and Teamwork: ${standardAspectsFeedback.leadershipAndTeamwork}
    `;
    navigator.clipboard.writeText(resultText);
    alert('Results copied to clipboard!');
  };

  return (
    <div className="p-8  bg-white  text-violet-900 text-center">
      <motion.h2
        className="text-4xl font-bold text-violet-900 mb-6"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        Interview Results
      </motion.h2>
      <div className="mb-6">
        <p className="text-2xl text-orange-500">Overall Score: <span className="font-semibold">{overallScore} / 10</span></p>
        <div className="w-full bg-white border rounded-full h-6 mt-4">
          <motion.div
            className={`${getBgColorForProgrssBar(overallScore)} h-6 rounded-full`}
            initial={{ width: 0 }}
            animate={{ width: `${overallScore * 10}%` }}
            transition={{ duration: 1 }}
          />
        </div>
      </div>
      <p className="text-xl text-violet-900 mb-8">Overall Remarks: <span className="font-medium">{overallRemarks}</span></p>

      <h3 className="text-3xl font-semibold text-violet-900 mb-6">Question-wise Feedback</h3>
      {questionWiseFeedback.map((feedback, index) => (
        <motion.div
          key={index}
          className={`mb-6 p-6 border rounded-lg shadow-md ${getBgColorForScore(feedback.score)} text-left `}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
        >
          <p className="text-xl text-violet-900 mb-2"><strong>Question:</strong> {feedback.question}</p>
          <p className={`text-xl mb-2 ${getColorForScore(feedback.score)}`}><strong>Score:</strong> {feedback.score} / 10</p>
          <p className={`text-xl mb-2 ${getColorForScore(feedback.score)}`}><strong>Remarks:</strong> {feedback.remarks}</p>
          <p className="text-xl text-black"><strong>Best Possible Answer:</strong> {feedback.bestAnswer}</p>
        </motion.div>
      ))}

      <h3 className="text-3xl font-semibold text-violet-900 mb-6">Standard Aspects Feedback</h3>
      <motion.div
        className="mb-6 p-6 border rounded-lg shadow-md bg-white text-left"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: questionWiseFeedback.length * 0.1 }}
      >
        <p className="text-xl text-violet-900 mb-2"><strong>Communication Skills:</strong> {standardAspectsFeedback.communicationSkills}</p>
        <p className="text-xl text-violet-900 mb-2"><strong>Problem-Solving Ability:</strong> {standardAspectsFeedback.problemSolvingAbility}</p>
        <p className="text-xl text-violet-900 mb-2"><strong>Technical Knowledge:</strong> {standardAspectsFeedback.technicalKnowledge}</p>
        <p className="text-xl text-violet-900 mb-2"><strong>Cultural Fit:</strong> {standardAspectsFeedback.culturalFit}</p>
        <p className="text-xl text-violet-900"><strong>Leadership and Teamwork:</strong> {standardAspectsFeedback.leadershipAndTeamwork}</p>
      </motion.div>

      <motion.div
        className="mt-8"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <p className="text-3xl font-semibold mt-8">{emoji} {message}</p>
      </motion.div>

      <div className="mt-8 flex justify-center space-x-4">
        <button
          onClick={handlePrint}
          className="px-4 py-2 bg-violet-900 text-white rounded-md hover:bg-violet-500 transition duration-300"
        >
          Print Results
        </button>
        <button
          onClick={handleCopy}
          className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-green-500 transition duration-300"
        >
          Copy Results
        </button>
      </div>
    </div>
  );
};

export default Results;