// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { motion } from 'framer-motion';

// const LoginPage = ({ onLogin }) => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false); // State for show password
//   const [error, setError] = useState("");
//   const navigate = useNavigate();

//   const handleLogin = (e) => {
//     e.preventDefault();
//     if (!email || !password) {
//       setError("Both fields are required.");
//       return;
//     }

//     // Dummy login validation
//     if (email === "admin@smakcoders.com" && password === "admin@1927") {
//       setError("");
//       navigate("/mock_interview");
//     } else {
//       setError("Invalid email or password.");
//     }
//   };

//   return (
//     <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-500 via-gray-800 to-black px-4 relative overflow-hidden">
//       <motion.div
//         className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-gray-500 via-gray-800 to-black opacity-50"
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         transition={{ duration: 2 }}
//       />
//       <motion.div
//         className="absolute top-0 left-0 w-full h-full bg-stars bg-cover"
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         transition={{ duration: 2, delay: 1 }}
//       />
//       <motion.div
//         className="absolute top-0 left-0 w-full h-full bg-stars bg-cover"
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         transition={{ duration: 2, delay: 2 }}
//       />
//       <motion.div
//         className="absolute top-0 left-0 w-full h-full bg-stars bg-cover"
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         transition={{ duration: 2, delay: 3 }}
//       />
//       <div className="bg-gradient-to-b from-gray-900 via-gray-900 to-black p-8 rounded-2xl shadow-xl max-w-sm w-full transform hover:scale-105 transition duration-300 z-10">
//         <h1 className="text-4xl font-extrabold text-white mb-6 text-center tracking-wide tracking-wider text-transparent bg-clip-text bg-gradient-to-r from-white to-indigo-600">
//           Aires Juniors
//         </h1>
//         <form className="space-y-6">
//           {/* Email Input */}
//           <div>
//             <label className="block text-white font-semibold mb-2">
//               Email Address
//             </label>
//             <input
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-400 focus:outline-none transition"
//               placeholder="Enter your email"
//             />
//           </div>

//           {/* Password Input */}
//           <div className="relative">
//             <label className="block text-white font-semibold mb-2">
//               Password
//             </label>
//             <input
//               type={showPassword ? "text" : "password"} // Toggle between text and password
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-400 focus:outline-none transition pr-10"
//               placeholder="Enter your password"
//             />
//             {/* "Show" Text Link */}
//             <button
//               type="button"
//               onClick={() => setShowPassword(!showPassword)} // Toggle state
//               className="absolute right-3 bottom-1 transform -translate-y-1/2 text-indigo-500 text-sm font-semibold hover:text-indigo-700 transition"
//             >
//               {showPassword ? "Hide" : "Show"}
//             </button>
//           </div>

//           {/* Error Message */}
//           {error && <p className="text-red-500 text-sm">{error}</p>}

//           {/* Login Button */}
//           <button
//             type="submit"
//             onClick={handleLogin}
//             className="w-full py-3 bg-gradient-to-r from-indigo-500 to-purple-600 text-white font-bold rounded-lg shadow-lg hover:shadow-2xl hover:from-purple-600 hover:to-indigo-500 transition duration-300"
//           >
//             Login
//           </button>
//         </form>

//         {/* Additional Links */}
//         <div className="mt-6 text-center">
//           <p className="text-white text-sm">
//             Don't have an account?{" "}
//             <a
//               href="#"
//               className="text-indigo-500 hover:underline font-semibold"
//             >
//               Sign Up
//             </a>
//           </p>
//           <p className="text-gray-600 text-sm mt-2">
//             <a
//               href="#"
//               className="text-indigo-500 hover:underline font-semibold"
//             >
//               Forgot Password?
//             </a>
//           </p>
//         </div>
//       </div>
//       {/* Floating Stars Animation */}
//       <motion.div
//         className="absolute top-0 left-0 w-full h-full pointer-events-none"
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         transition={{ duration: 2, delay: 4 }}
//       >
//         <div className="absolute top-0 left-0 w-full h-full bg-stars bg-cover opacity-50 animate-float" />
//         <div className="absolute top-0 left-0 w-full h-full bg-stars bg-cover opacity-50 animate-float animation-delay-2000" />
//         <div className="absolute top-0 left-0 w-full h-full bg-stars bg-cover opacity-50 animate-float animation-delay-4000" />
//       </motion.div>
//     </div>
//   );
// };

// export default LoginPage;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';

const LoginPage = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for show password
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError("Both fields are required.");
      return;
    }

    // Dummy login validation
    if (email === "admin@smakcoders.com" && password === "admin@1927") {
      setError("");
      navigate("/mock_interview");
    } else {
      setError("Invalid email or password.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-violet-900 to-white px-10">
      <div className="bg-white p-8 rounded-2xl shadow-xl max-w-sm w-full z-10">
        <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-violet-900 mb-6 text-center">
          aire<span className="text-xl font-small text-transparent bg-clip-text bg-orange-500">jr</span>
        </h1>
        <form className="space-y-6">
          <div>
            <label className="block text-violet-900 font-semibold mb-2">Email Address</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-400 focus:outline-none transition text-violet-900"
              placeholder="Enter your email"
            />
          </div>
          <div className="relative">
            <label className="block text-violet-900 font-semibold mb-2">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-400 focus:outline-none transition pr-10 text-violet-900"
              placeholder="Enter your password"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 bottom-1 transform -translate-y-1/2 text-violet-700 text-sm font-semibold hover:text-violet-900 transition"
            >
              {showPassword ? "Hide" : "Show"}
            </button>
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <button
            type="submit"
            onClick={handleLogin}
            className="w-full py-3 bg-orange-600 text-white font-bold rounded-lg shadow-lg hover:shadow-2xl transition duration-300"
          >
            Login
          </button>
        </form>
        <div className="mt-6 text-center">
          <p className="text-black text-sm">
            Don't have an account?{" "}
            <a href="#" className="text-violet-700 hover:underline font-semibold">Sign Up</a>
          </p>
          <p className="text-gray-600 text-sm mt-2">
            <a href="#" className="text-violet-700 hover:underline font-semibold">Forgot Password?</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;