import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';

const CodingPractice = () => {
  const [step, setStep] = useState(1);
  const [jobTarget, setJobTarget] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const languages=[
  "JavaScript",
  "Python",
  "Java",
  "C++",
  "C#",
  "Ruby",
  "Go",
  "Swift",
  "Kotlin",
  "PHP",
  "R",
  "TypeScript",
  "Scala",
  "Perl",
  "Dart",
  "Rust",
  "MATLAB",
  "Objective-C",
  "Shell",
  "Haskell",
  "SQL",
  "Lua"
];
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [answers, setAnswers] = useState({});
  const [timer, setTimer] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [evaluation, setEvaluation] = useState(null);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    } else if (timeLeft === 0 && step === 2) {
     // handleFinishTest();
    }
  }, [timeLeft, step]);

  const handleNextStep = async () => {
  if (step === 2) {
    try {
      setLoading(true);
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o-mini',
          temperature: 1,
          messages: [
            {
              role: 'user',
              content: `Generate a comprehensive set of coding questions to evaluate a ${selectedLanguage} developer's knowledge. The questions should assess various skills, including problem-solving, algorithm design, data structures, debugging, and real-world application scenarios. The levels should include easy, medium, and hard questions.

Each question must include:
- A detailed description of the problem, emphasizing practical, real-world coding scenarios.
- Constraints and edge cases that need to be handled.
- A hint (optional) to guide the candidate.
- Sample input(s) and corresponding output(s) do not provide exact answer.

Ensure the questions align with common tasks the developer might encounter in a professional setting. Provide the response strictly in the following JSON format:

{
  "questions": [
    {
      "level": "easy",
      "question": "Question text here",
      "hint": "Optional hint or null",
      "sampleInput": "Input example here",
      "sampleOutput": "Expected output example here"
    },
    {
      "level": "medium",
      "question": "Question text here",
      "hint": "Optional hint or null",
      "sampleInput": "Input example here",
      "sampleOutput": "Expected output example here"
    },
    {
      "level": "hard",
      "question": "Question text here",
      "hint": "Optional hint or null",
      "sampleInput": "Input example here",
      "sampleOutput": "Expected output example here"
    }
  ]
}

Return the response only in the specified JSON format. Do not include additional text, explanations, or formatting like \`\`\`json.`,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer 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`,
            'Content-Type': 'application/json',
          },
        }
      );

      const rawContent = response.data.choices[0].message.content;

      // Clean JSON response by removing extra formatting (if present)
      const cleanedContent = rawContent.replace(/```json|```/g, '').trim();

      // Attempt to parse the cleaned content
      let questionsList;
      try {
        questionsList = JSON.parse(cleanedContent);
      } catch (jsonError) {
        throw new Error(`Invalid JSON format: ${jsonError.message}`);
      }

      // Extract questions and other details
      setQuestions(questionsList.questions || []);
      setTimer(questionsList.time || 0);
      setTimeLeft(questionsList.time || 0);

      // Move to the next step
      setStep(3);
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      setLoading(false);
    }
  }
};


 const handleFinishTest = async () => {
  const updatedAnswers = { ...answers };
  questions.forEach((question) => {
    if (!updatedAnswers[question.question]) {
      updatedAnswers[question.question] = 'Question not answered';
    }
  });

  try {
    setLoading(true);

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'user',
            content: `strictly Evaluate the following answers for a ${selectedLanguage} developer role. Provide the response as a JSON object in the exact format below. Do not include any additional information, explanations.

Expected JSON format:
{
  "evaluation": [
    {
      "question": "Question_1 text here",
      "score": "Score for this answer (e.g., Excellent, Good, Needs Improvement)",
      "your_answer": "The answer provided by the candidate if no answer provided, mention 'Question not answered'",
      "remarks": "Specific feedback or observations about the answer",
      "best_way": "Detailed solution to this question written without using any standard inbuilt libraries or packages, ensuring simplicity and readability also provide in such a way that it can be understood by a beginner.also provide comments to step. also provide response in such a way that this code can be render in code form line by line."
    }
  ]
}`,
          },
          ...Object.entries(updatedAnswers).map(([question, answer]) => ({
            role: 'user',
            content: `Question: ${question}\nAnswer: ${answer}`,
          })),
        ],
      },
      {
        headers: {
          Authorization: `Bearer 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`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('Raw API response:', response.data.choices[0].message.content);

    const sanitizedContent = response.data.choices[0].message.content.replace(/```json|```/g, '').trim();

    let evaluationResult;
    try {
      evaluationResult = JSON.parse(sanitizedContent);
    } catch (parseError) {
      console.error('Error parsing JSON:', parseError, 'Content:', sanitizedContent);
      setLoading(false);
      return;
    }

    if (!evaluationResult || !evaluationResult.evaluation) {
      console.error('Invalid response structure:', evaluationResult);
      setLoading(false);
      return;
    }

    setEvaluation(evaluationResult.evaluation);
    console.log(evaluationResult.evaluation);

    setStep(4);
    setLoading(false);
  } catch (error) {
    console.error('Error evaluating answers:', error);
    setLoading(false);
  }
};


  const handleQuestionClick = (question) => {
    setCurrentQuestion(question);
  };

  const handleCodeChange = (e) => {
    setAnswers({ ...answers, [currentQuestion.question]: e.target.value });
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-violet-900 px-4">
      {step === 1 && (
  <motion.div
    className="bg-gradient-to-br from-white to-blue-50 p-6 sm:p-8 lg:p-10 rounded-3xl shadow-2xl w-full max-w-4xl mx-auto border-t-8 border-violet-600"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, ease: "easeOut" }}
  >
    <h1 className="text-3xl sm:text-4xl lg:text-6xl font-extrabold text-violet-900 mb-4 sm:mb-6 text-center tracking-wide">
      Ready to Ace Your Coding Interviews?
    </h1>
    <p className="text-base sm:text-lg lg:text-xl text-violet-700 mb-6 sm:mb-8 text-center italic">
      Elevate your coding skills with challenges that *actually* prepare you for real-world interview questions.
    </p>
    <div className="mb-6 sm:mb-8 bg-gradient-to-r from-violet-100 to-blue-100 p-5 sm:p-8 rounded-2xl shadow-md">
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold text-gray-800 mb-4 sm:mb-5">
        Why This Practice Stands Out:
      </h2>
      <ul className="list-disc pl-4 sm:pl-6 space-y-3 sm:space-y-4 text-sm sm:text-base lg:text-lg text-gray-700">
        <li>
          <strong className="text-violet-900">Real-World Focus:</strong> These challenges mimic the tricky questions top companies love to ask—so you’ll feel confident tackling any curveball.
        </li>
        <li>
          <strong className="text-violet-900">Skill Sharpening:</strong> Master key concepts like algorithms, recursion, dynamic programming, and optimization strategies, all designed to boost your coding speed and accuracy.
        </li>
        <li>
          <strong className="text-violet-900">Targeted Practice:</strong> Unlike generic coding platforms, these exercises are tailored to your dream roles—Frontend Developer, Data Scientist, or Software Engineer.
        </li>
      </ul>
    </div>
    <p className="text-sm sm:text-base lg:text-lg text-gray-800 mb-6 sm:mb-8 text-center">
      Stop wasting time on random problems. Start practicing with purpose and land your dream job faster!
    </p>
    <button
      onClick={() => setStep(2)}
      className="w-full px-6 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-orange-500 via-orange-500 to-orange-500 text-white font-bold rounded-full shadow-xl hover:scale-105 hover:shadow-2xl transform transition-all duration-300"
    >
      Let’s Get Started!
    </button>
  </motion.div>
)}

     {step === 2 && (
  <motion.div
    className="bg-gradient-to-br from-white to-gray-50 p-6 sm:p-8 lg:p-10 rounded-3xl shadow-2xl w-full max-w-4xl mx-auto border-t-8 border-violet-600"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, ease: "easeOut" }}
  >
    <h1 className="text-2xl sm:text-3xl lg:text-4xl font-extrabold text-violet-900 mb-4 sm:mb-6 text-center">
      Select Programming Language
    </h1>
    <div className="mb-6">
      <label className="block text-sm sm:text-base lg:text-lg font-medium text-gray-800 mb-3">
        Choose a programming language for your practice:
      </label>
      <div className="relative w-full">
        {/* Custom Dropdown */}
        <button
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="w-full p-4 text-left border border-gray-300 rounded-xl bg-gradient-to-r from-gray-100 to-gray-200 text-gray-800 text-sm sm:text-base lg:text-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-violet-500 hover:bg-gray-50 transition-all flex justify-between items-center"
        >
          <span>{selectedLanguage || "Select a language"}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`w-5 h-5 text-gray-500 transition-transform ${
              dropdownOpen ? "rotate-180" : ""
            }`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>

        {/* Dropdown Menu */}
        {dropdownOpen && (
          <ul className="absolute w-full bg-white border border-gray-300 rounded-xl shadow-lg mt-2 max-h-60 overflow-y-auto z-10">
            {languages.map((lang, index) => (
              <li
                key={index}
                onClick={() => {
                  setSelectedLanguage(lang);
                  setDropdownOpen(false);
                }}
                className="px-4 py-3 text-gray-800 hover:bg-violet-100 hover:text-violet-900 cursor-pointer transition-all"
              >
                {lang}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
    <button
      onClick={handleNextStep}
            className="w-full px-4 sm:px-6 py-3 sm:py-4 bg-gradient-to-r from-orange-500 to-orange-500 text-white text-sm sm:text-base lg:text-lg font-bold rounded-full shadow-lg hover:shadow-xl hover:scale-105 transform transition-all duration-300"
            disabled={loading}
    >
      {loading ? 'Preparing challenge...' : 'Start Practice'}
    </button>
  </motion.div>
)}

     {step === 3 && (
  <motion.div
    className="bg-gradient-to-br from-white to-white p-8 sm:p-10 lg:p-12 rounded-3xl shadow-2xl w-full max-w-4xl mx-auto border-t-8 border-violet-500"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, ease: "easeOut" }}
  >
    <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold text-violet-900 mb-8 text-center">
      Coding Practice
    </h1>

    {/* Question List Section */}
          {!currentQuestion && (<div className="mb-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {Array.isArray(questions) && questions.map((question, index) => (
                <div
                  key={index}
                  className={`p-6 rounded-lg cursor-pointer border-2 transition-transform transform hover:scale-105 ${answers[question.question] ? 'border-green-500' : 'border-red-600'
                    } hover:bg-gradient-to-br hover:from-white hover:to-white shadow-xl hover:shadow-2xl duration-300`}
                  onClick={() => handleQuestionClick(question)}
                >
                  <h3 className="text-lg sm:text-xl font-semibold text-violet-900 mb-3">
                    Question {index + 1}
                  </h3>
                  <p className="text-base text-gray-900">{question.question}</p>
                </div>
              ))}
            </div>
          </div>)}

    {/* Current Question Section */}
    {currentQuestion && (
      <div className="mb-8 bg-white p-6 rounded-lg shadow-2xl">
        <h2 className="text-2xl sm:text-3xl font-semibold text-black mb-4">
          {currentQuestion.question}
        </h2>
        <div className="text-sm text-orange-500 mb-3">Sample Input: {currentQuestion.sampleInput}</div>
        <div className="text-sm text-green-500 mb-4">Sample Output: {currentQuestion.sampleOutput}</div>

      <div className="relative w-full h-64 bg-gray-900 rounded-lg shadow-lg overflow-hidden">
  {/* Line Numbers Panel */}
  {/* <div className="absolute top-0 left-0 h-full w-12 bg-gray-800 text-gray-400 text-sm font-mono flex flex-col items-center pt-4 overflow-hidden">
   
    {answers[currentQuestion.question]?.split("\n").map((_, i) => (
      <div key={i} className="text-right pr-2">
        {i + 1}
      </div>
    ))}
  </div> */}

  {/* Code Input Area */}
  <textarea
                  value={answers[currentQuestion.question] || ''} // Bind the text area's value to the current answer
                  onChange={handleCodeChange} // Handle changes to the input
                     className="w-full h-full  sm:pl-18 md:pl-20 p-4 bg-transparent text-gray-100 border-none focus:outline-none font-mono text-xs sm:text-sm md:text-base resize-none overflow-x-auto whitespace-nowrap placeholder-green-600"

                  placeholder={languages !=="SQL"?"/* Write your code here... */":"/* Write your query here... */"} // Placeholder text for the input
  />
</div>



        <button
          onClick={() => setCurrentQuestion(null)}
          className="mt-4 px-6 py-3 bg-gradient-to-r from-violet-900 to-violet-900 text-white font-semibold rounded-full shadow-lg hover:shadow-2xl hover:scale-105 transition-all duration-300"
        >
          Save & Back to Questions
        </button>
      </div>
    )}

    {/* Finish Test Button */}
    <button
      onClick={handleFinishTest}
            className="w-full px-6 py-3 bg-gradient-to-r from-orange-500 to-orange-500 text-white font-semibold rounded-full shadow-lg hover:shadow-2xl hover:scale-105 transition-all duration-300 mt-6"
            disabled={loading}
    >
      {loading ? 'Evaluating answers...' : 'Finish Test'}
    </button>
  </motion.div>
)}


   {step === 4 && (
  <motion.div
    className="bg-gradient-to-r from-white to-white p-6 sm:p-8 md:p-10 lg:p-12 xl:p-16 rounded-3xl shadow-2xl w-full max-w-4xl mx-auto border-t-8 border-violet-600"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, ease: "easeOut" }}
  >
    <h1 className="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-extrabold text-gradient-to-r from-violet-900 to-violet-900 mb-6 sm:mb-8 text-center">
      Evaluation Result
    </h1>

    {/* Evaluation Content */}
    {evaluation && (
      <div>
        {evaluation.map((result, index) => {
          // Determine color for border, shadow, and score text
          const scoreColor =
            result.score === "Excellent"
              ? "border-green-500 shadow-green-200" // Excellent
              : result.score === "Good"
              ? "border-yellow-500 shadow-yellow-200" // Good
              : "border-red-500 shadow-red-200"; // Needs Improvement

          const textColor =
            result.score === "Excellent"
              ? "text-green-600"
              : result.score === "Good"
              ? "text-yellow-600"
              : "text-red-600";

          return (
            <div
              key={index}
              className={`mb-6 sm:mb-8 lg:mb-10 p-6 sm:p-8 border-2 rounded-xl shadow-xl ${scoreColor}`}
            >
              <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold text-violet-700 mb-4">
                Question {index + 1}
              </h2>

              {/* Displaying Result Details */}
              <div className="text-sm sm:text-base text-gray-900">
                <p className="mb-2">
                  <strong className="text-orange-500">Question:</strong> {result.question}
                </p>
                <p className={`mb-2 font-bold ${textColor}`}>
                  <strong className="text-orange-500">Performance:</strong> {result.score}
                </p>
                <p className="mb-2">
                  <strong className="text-orange-500">Your Answer:</strong> {result.your_answer}
                </p>
                <p className="mb-2">
                  <strong className="text-orange-500">Remarks:</strong> {result.remarks}
                </p>
                <p className="mb-4">
                  <strong className="text-orange-500">Best Way to Deal:</strong>
                  <pre className="whitespace-pre-wrap break-words bg-gray-800 p-3 sm:p-4 rounded-md resize-none overflow-x-auto ">
                    <code>
                      {result.best_way.split("\n").map((line, index) => (
                        <span
                          key={index}
                          className={line.trim().startsWith("//") ? "text-green-500" : "text-white"}
                        >
                          {line}
                          <br />
                        </span>
                      ))}
                    </code>
                  </pre>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    )}

    {/* Back to Questions Button */}
    <div className="mt-6 sm:mt-8 flex justify-center">
      <button
        onClick={() => { 
          setStep(1); 
          setEvaluation(null);
          setAnswers({});
          setCurrentQuestion(null);
          setDropdownOpen(false);
          setLoading(false);
          setQuestions([]);
          setSelectedLanguage('');
        }}
        className="px-6 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-orange-500 to-orange-500 text-white font-semibold rounded-full shadow-lg hover:shadow-2xl transition-all duration-300"
      >
        Practice Again
      </button>
    </div>
  </motion.div>
)}

    </div>
  );
};

export default CodingPractice;