import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    // Add your logout logic here
    console.log('User logged out');
    navigate('/'); // Navigate to the login page
  };

  const handleNavLinkClick = (path) => {
    navigate(path);
    if (isOpen) {
      setIsOpen(false); // Close the navbar on mobile after selecting a navlink
    }
  };

  return (
    <div className="md:w-64 w-full md:h-screen bg-white to-black text-violet-900 flex flex-col md:fixed shadow-lg">
      <div className="flex justify-between items-center p-5 md:hidden">
        <div className="text-4xl font-bold tracking-wider text-transparent bg-clip-text bg-violet-900">
          aire<span className="text-xl font-small text-transparent bg-clip-text bg-orange-500">jr</span>
        </div>
        <button onClick={toggleNavbar} className="text-2xl focus:outline-none">
          {isOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>
      <motion.div
        className={`md:flex flex-col flex-1 ${isOpen ? 'block' : 'hidden md:block'}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="p-4 text-4xl font-bold tracking-wider text-center bg-clip-text bg-violet-900 hidden md:block mb-8">
           aire<span className="text-xl font-small text-transparent bg-clip-text bg-orange-500">jr</span>
        </div>
        <nav className="flex-1 ">
          <ul className="space-y-4">
            <motion.li
             
              
            >
              <NavLink
                to="/mock_interview"
                className={({ isActive }) =>
                  `block px-4 py-2 rounded-md transition duration-300 ${
                    isActive ? 'bg-orange-500 text-white' : 'hover:bg-orange-400 hover:text-white hover:scale-105'
                  }`
                }
                onClick={() => handleNavLinkClick('/mock_interview')}
              >
                Mock Interview
              </NavLink>
            </motion.li>
            <motion.li
             
            >
              <NavLink
                to="/Email_writing"
                className={({ isActive }) =>
                  `block px-4 py-2 rounded-md transition duration-300 ${
                    isActive ? 'bg-orange-500 text-white' : 'hover:bg-orange-400 hover:text-white hover:scale-105'
                  }`
                }
                onClick={() => handleNavLinkClick('/Email_writing')}
              >
                Email Writing
              </NavLink>
            </motion.li>
            <motion.li
             
            >
              <NavLink
                to="/Aptitude"
                className={({ isActive }) =>
                  `block px-4 py-2 rounded-md transition duration-300 ${
                    isActive ? 'bg-orange-500 text-white' : 'hover:bg-orange-400 hover:text-white hover:scale-105'
                  }`
                }
                onClick={() => handleNavLinkClick('/Aptitude')}
              >
                Aptitude
              </NavLink>
            </motion.li>
            <motion.li
             
            >
              <NavLink
                to="/Coding_Practice"
                className={({ isActive }) =>
                  `block px-4 py-2 rounded-md transition duration-300 ${
                    isActive ? 'bg-orange-500 text-white' : 'hover:bg-orange-400 hover:text-white hover:scale-105'
                  }`
                }
                onClick={() => handleNavLinkClick('/Coding_Practice')}
              >
                Coding Practice
              </NavLink>
                      </motion.li>
                       <motion.li
             
            >
              <NavLink
                to="/ResumeBuilder"
                className={({ isActive }) =>
                  `block px-4 py-2 rounded-md transition duration-300 ${
                    isActive ? 'bg-orange-500 text-white' : 'hover:bg-orange-400 hover:text-white hover:scale-105'
                  }`
                }
                onClick={() => handleNavLinkClick('/ResumeBuilder')}
              >
                Resume Builder
              </NavLink>
            </motion.li>
          </ul>
        </nav>
        <div className="mt-auto p-4">
          <button
            onClick={handleLogout}
            className="w-full px-4 py-2 bg-violet-900 text-white font-bold rounded-lg shadow-lg hover:shadow-2xl hover:from-purple-600 hover:to-indigo-500 transition duration-300 md:mt-4 md:mb-0 mb-2 mt-4"
          >
            Logout
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default Navbar;