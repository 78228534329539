import React, { useState, useEffect, useCallback } from "react";
import FileUpload from "./FileUpload";
import Results from "./Results";
import axios from "axios";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { motion ,AnimatePresence} from 'framer-motion';

const InterviewPage = () => {
  const [jobDescription, setJobDescription] = useState("");
  const [resume, setResume] = useState("");
  const [evaluation, setEvaluation] = useState(null);
  const [startedInterview, setStartedInterview] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [interviewCompleted, setInterviewCompleted] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(120); // 2 minutes timer for each question (120 seconds)
  const [error, setError] = useState(""); // For handling errors
   const [processing, setProcessing] = useState(false); // Tracks speech processing state
  const [finalText, setFinalText] = useState("");
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  // Ensure browser support for SpeechRecognition
  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      alert("Voice recognition is not supported in this browser.");
    }
  }, [browserSupportsSpeechRecognition]);
  const enhanceTranscript = async (rawTranscript, resume) => {
   const prompt = `
Correct only garbled or incorrect words in the transcript using both the context of the transcript and the resume:
- Correct words only if they are incorrect or garbled.
- Use relevant technical terms from the resume only when they align with the transcript's context.
- Avoid replacing terms that are already correct and contextually appropriate.
- Replace vulgar or inappropriate words with neutral placeholders like "[inappropriate language]" or equivalent contextually appropriate terms.
- Preserve phrases like "I don't know" without any changes.
- Retain the rest of the transcript as-is.
Output in the JSON format:
{
  "correctedTranscript": "Corrected version of the transcript goes here."
}
Transcript: "${rawTranscript}"
Resume: "${resume}"
`;

  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions', 
      {
        model: 'gpt-3.5-turbo',
        temperature: 0.2, 
       messages: [
          { role: "system", content: "You are a tool for correcting transcripts by fixing only incorrect keywords using resume context and addressing inappropriate language." },
          { role: "user", content: prompt },
        ],
      },
      {
        headers: {
          Authorization: `Bearer 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`, // Replace with your key
          'Content-Type': 'application/json',
        },
      }
    );
    
    return JSON.parse(response.data.choices[0].message.content.replace(/```json|```/g, '')).correctedTranscript;
  } catch (error) {
    return rawTranscript; // Fallback to raw transcript if API fails
  }
};

  // Save voice input to the current question
  const handleVoiceInput = useCallback(async () => {
  const currentQuestion = questions[questionIndex];
  const newAnswers = { ...userAnswers };

  let finalTranscript = transcript;

  // If transcript is present, enhance it, else use it as is
  if (transcript && transcript.trim()) {
    finalTranscript = await enhanceTranscript(transcript, resume);
  }

  // Append final transcript to the existing answer
  newAnswers[currentQuestion] = (newAnswers[currentQuestion] || "") + " " + finalTranscript;
  setUserAnswers(newAnswers);
  resetTranscript(); // Clear transcript after saving
  setProcessing(false); // Stop processing
  setFinalText(newAnswers[currentQuestion]); // Update final text
}, [transcript, questions, questionIndex, userAnswers, setUserAnswers, resetTranscript, resume]);

  // Stop listening and process the final transcript
  const handleStop = useCallback(() => {
   setProcessing(true); // Start processing

  // Add a short delay to ensure final words are captured
  setTimeout(() => {
    SpeechRecognition.abortListening({
      interimResults: false,
    }); // Stop listening
    setTimeout(() => {
      handleVoiceInput(); // Process the transcript
    }, 500); // Allow time for the final transcript to finalize
  }, 3000); // Grace period before stopping
  }, [handleVoiceInput]);

  // Start or stop recording
  const toggleRecording = useCallback(() => {
    if (listening) {
      handleStop();
    } else {
      resetTranscript();
      setFinalText(""); // Clear previous session data
      SpeechRecognition.startListening({
        continuous: true,
        interimResults: false,
        language: "en-IN",
      });
    }
  }, [listening, handleStop, resetTranscript]);


  // Login handler
  const handleLogin = () => {
    setIsLoggedIn(true);
  };




  // Handle file uploads
  const handleFilesUploaded = ({ type, content }) => {
    if (type === "resume") {
      setResume(content);
    } else if (type === "jobDescription") {
      setJobDescription(content);
    }
  };

  // Start the interview and fetch questions from the API
  const startInterview = async () => {
    setStartedInterview(true);
    setInterviewCompleted(false);
    setError(""); // Clear previous errors

    setLoading(true);
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions', 
        {
          model: 'gpt-4o-mini',
          temperature: 1, 
          messages: [
            {
              role: 'system',
              content: 'You are an AI interviewer generating dynamic questions for a job interview.',
            },
            {
              role: 'user',
              content: generatePrompt(resume, jobDescription),
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer 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`,
            'Content-Type': 'application/json',
          },
        }
      );

      const newQuestions = JSON.parse(response.data.choices[0].message.content.replace(/```json|```/g, ''));
      
      setQuestions(newQuestions.questions);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Error fetching questions. Please try again later.");
    }
  };

 
const generatePrompt = (resume, jobDescription) => `
  Resume: ${resume}
  Job Description: ${jobDescription}

  Generate the following questions:
  - Self-introduction.
  - Project explanation.
  - 3 skill-based theoretical questions.
  - 2 job-specific questions based on key responsibilities, industry knowledge, or tools mentioned in the job description.
  - 3 scenario-based questions simulating real-world challenges, reflecting experiences in the resume or key responsibilities in the job description.

  {
    "questions": [
      "<Self-introduction question>",
      "<Project-based question>",
      "<Theoretical question 1>",
      "<Theoretical question 2>",
      "<Theoretical question 3>",
      "<Job-specific question 1>",
      "<Job-specific question 2>",
      "<Scenario-based question 1>",
      "<Scenario-based question 2>",
      "<Scenario-based question 3>"
    ],
    
  }
`;
  // Handle answer change
  const handleAnswerChange = (e) => {
    const newAnswers = { ...userAnswers };
    const currentQuestion = questions[questionIndex];
    newAnswers[currentQuestion] = e.target.value;
    setUserAnswers(newAnswers);
  };
  // Move to the next question or complete the interview
  const handleNextQuestion = () => {
    
  if (listening) {
    SpeechRecognition.stopListening();
    handleVoiceInput(); // Handle voice input when stopping the recording
  }

  if (questionIndex < questions.length - 1) {
    setQuestionIndex(questionIndex + 1);
    setTimeLeft(120);
    resetTranscript(); // Reset the transcript for the next question
    // Reset timer for the next question
  } else {
    evaluateAnswers();
  }
};

  // Timer logic: Countdown for each question
  useEffect(() => {
  let timer;
  if (startedInterview && timeLeft > 0 && !interviewCompleted) {
    timer = setInterval(() => setTimeLeft((prevTime) => prevTime - 1), 1000);
  } else if (timeLeft === 0) {
    // Auto-fill unanswered question with "Not Answered"
    const currentQuestion = questions[questionIndex];
    if (!userAnswers[currentQuestion]) {
      const newAnswers = { ...userAnswers };
      newAnswers[currentQuestion] = transcript || "Not Answered within given time limit";
      setUserAnswers(newAnswers);
    }
    SpeechRecognition.stopListening(); // Stop recording when the timer is over
    handleNextQuestion();
    // Move to next question after time is up
  }
  return () => clearInterval(timer);
}, [startedInterview, timeLeft, interviewCompleted, questionIndex, questions, handleNextQuestion, transcript, userAnswers]);
 const evaluateAnswers = async () => {
  try {
    // Make API request to evaluate the answers
    setLoading(true);
    setError(""); // Clear any previous error
    setQuestions([]);
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'system',
            content: 'You are a highly skilled interviewer, evaluating job candidates strictly based on their responses.',
          },
          {
            role: 'user',
            content: generateEvaluationPrompt(userAnswers, jobDescription),
          },
        ],
      },
      {
        headers: {
          Authorization: `Bearer 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`,
          'Content-Type': 'application/json',
        },
      }
    );

    // Get the evaluation result
    const evaluationContent = response.data.choices[0].message.content;
    const evaluation = JSON.parse(evaluationContent.replace(/```json|```/g, ''));
   
    
    // Set the evaluation in the state
    setInterviewCompleted(true);
    setEvaluation(evaluation);
  } catch (error) {
    setError("Error evaluating answers. Please try again.");
   
  } finally {
    setLoading(false); // Set loading state to false after API request is completed
  }
};

  // Generate the prompt for the evaluation
 const generateEvaluationPrompt = (answers, jobDescription) => {
  return `
    Job Description:
    ${jobDescription}

    Evaluate the candidate's answers. Provide:
    1. Validity of answers.
    2. Overall remarks.
    3. Question-wise score (0-10). Score 0 if not valid.
    4. Question-wise remarks.
    5. Best possible answer.

    Answers:
    ${Object.entries(answers)
      .map(([question, answer]) => `${question}: ${answer}`)
      .join('\n')}

    Ensure answers are genuine, relevant, and detailed. Score 0 if not appropriate.

    Respond in JSON:
    {
      "overallRemarks": "<overall_remarks>",
      "questionWiseFeedback": [
        ${Object.entries(answers).map(([question, answer]) => `
        {
          "question": "${question}",
          "score": "<score>",
          "remarks": "<remarks>",
          "bestAnswer": "<best_answer>"
        }`).join(',')}
      ],
      "standardAspectsFeedback": {
        "communicationSkills": "<communication_skills_feedback>",
        "problemSolvingAbility": "<problem_solving_ability_feedback>",
        "technicalKnowledge": "<technical_knowledge_feedback>",
        "culturalFit": "<cultural_fit_feedback>",
        "leadershipAndTeamwork": "<leadership_and_teamwork_feedback>"
      }
    }
  `;
};
  return (
      <>
         
      {interviewCompleted ? (
        <div className="min-h-screen flex items-center justify-center ">
          <div className="w-full max-w-4xl bg-white text-black rounded-xl shadow-2xl p-6 sm:p-8 md:p-10">
            {error ? (
              <div className="text-center">
                <p className="text-red-500">{error}</p>
                <button
                  onClick={evaluateAnswers}
                  className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Retry Evaluation
                </button>
              </div>
            ) : (
              <Results evaluation={evaluation} />
            )}
          </div>
        </div>
      ) : (
        <div className="min-h-screen flex items-center justify-center ">
          <div className="w-full max-w-4xl  text-black rounded-xl p-6 sm:p-8 md:p-10 border rounded-lg shadow-lg max-w-3xl mx-auto bg-white">
            {error && <p className="text-red-500">{error}</p>}
            {!resume || !jobDescription || !startedInterview ? (
              <FileUpload onFilesUploaded={handleFilesUploaded} onStartInterview={startInterview} />
            ) : (
              <div className="space-y-6 ">
                {questions.length > 0 && (
                  <div className="space-y-4">
                    <h2 className="text-2xl font-semibold text-violet-900">
                      Question {questionIndex + 1}
                    </h2>
                    <p className="text-lg text-black">{questions[questionIndex]}</p>
                    <textarea
                      placeholder="Your answer here..."
                      value={userAnswers[questions[questionIndex]] || ''}
                      onChange={handleAnswerChange}
                      rows="5"
                      className="w-full p-3 border border-violet-500 rounded-md focus:outline-none focus:ring-2 focus:ring-violet-500 bg-white text-black"
                    />
                    <AnimatePresence>
                      {listening && (
                        <motion.div
                          className="mt-6 p-5 bg-gradient-to-r from-yellow-50 to-yellow-100 border-l-8 border-yellow-500 rounded-lg shadow-md flex items-center gap-4"
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 20 }}
                          transition={{ duration: 0.5, ease: "easeInOut" }}
                        >
       <motion.div
                            className="w-4 h-4 rounded-full bg-red-500 animate-pulse"
                            initial={{ scale: 1 }}
                            animate={{ scale: 1.2 }}
                            transition={{
                              repeat: Infinity,
                              duration: 0.8,
                              repeatType: "reverse",
                            }}
                          />
                          <p className="text-yellow-700 font-medium">Recording in progress...</p>
                        </motion.div>
                      )}
                    </AnimatePresence>
                    <button
                      onClick={toggleRecording}
                      className={`px-6 py-3 font-semibold rounded-lg text-white shadow-lg ${
                        listening ? processing ? "bg-gradient-to-r from-green-500 to-green-700" : "bg-gradient-to-r from-red-500 to-red-700" : "bg-violet-900"
                      } hover:shadow-xl transform hover:scale-105 transition-transform duration-300`}
                    >
                      {listening ? processing ? "Processing speech..." : "Stop Recording" : "Start Recording"}
                    </button>
                    <div className="flex justify-between items-center">
                      <p className="text-sm text-violet-500">Time Left:<span className="text-lg text-violet-900"> {timeLeft}s</span></p>
                      <button
                        onClick={handleNextQuestion}
                        disabled={!userAnswers[questions[questionIndex]] && !listening && !processing} // Disable if no input
                        className={`px-6 py-3 font-semibold rounded-lg text-white shadow-lg ${
                          userAnswers[questions[questionIndex]] && !listening && !processing
                            ? "bg-orange-500 hover:bg-orange-400"
                            : "bg-gray-600 cursor-not-allowed"
                        }`}
                      >
                       {questionIndex === questions.length - 1 ? "Finish Interview" : "Next Question"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {loading && !interviewCompleted && (
              <div className="text-center">
                <div className="loader"></div>
                <p> Loading ...</p>
              </div>
            )}
          </div>
        </div>
              )}
              
    </>
  );
};

export default InterviewPage;