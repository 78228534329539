import React from 'react';
import { motion } from 'framer-motion';

const Aptitude = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen  text-white px-4 text-center">
      <motion.h1
        className="text-5xl text-violet-900 font-bold mb-4"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        Coming Soon
      </motion.h1>
      <motion.p
        className="text-lg text-orange-500 font-bold mb-4"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        We are working hard to bring you this feature. Stay tuned!
      </motion.p>
    </div>
  );
};

export default Aptitude;